const infoGraphicOpenBtn = document.querySelectorAll(
  ".js-modal-open-btn"
);
const infoGraphicModals = document.querySelectorAll(
  ".info-graphic__diagram-modal"
);
const infoGraphicModalWrap = document.querySelector(
  ".info-graphic__diagram-modal-wrap"
);
const infoGraphicModalClose = document.querySelectorAll(
  ".info-graphic__diagram-modal__close"
);

let isValid = false;

for (let i = 0; i < infoGraphicOpenBtn.length; i++) {
  infoGraphicOpenBtn[i].addEventListener("click", () => {
    if (isValid) {
      infoGraphicModalWrap.classList.add("is-open");
      infoGraphicModals[i].classList.add("is-open");
    }
  });

  infoGraphicModalClose[i].addEventListener("click", () => {
    if (isValid) {
      infoGraphicModalWrap.classList.remove("is-open");
      infoGraphicModals[i].classList.remove("is-open");
    }
  });

  infoGraphicModalWrap.addEventListener("click", (e) => {
    if (isValid) {
      infoGraphicModalWrap.classList.remove("is-open");
      infoGraphicModals[i].classList.remove("is-open");
    }
  });

  infoGraphicModals[i].addEventListener("click", (e) => {
    e.stopPropagation();
  });
}

function checkWindowSize() {
  if (window.innerWidth > 1024) {
    isValid = false;
  } else {
    isValid = true;
  }
}
window.addEventListener("resize", checkWindowSize);
checkWindowSize();
